
/* Chrome, Safari, Edge, Opera */
  input[name='removeArrows']::-webkit-outer-spin-button,
  input[name='removeArrows']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[name="removeArrows"] {
    -moz-appearance: textfield;
  }
  